import React from "react";
import { OutlineButton } from "../components/button";

interface HeroHeaderProps {
  headerText: string;
  subheaderText: string;
  paragraphText: string;
  buttonText?: string;
  buttonLink?: string;
  imageSource: string;
  imageAltText: string;
}

const HeroHeader = (props: HeroHeaderProps) => {
  return (
    <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20">
      {/* Text Content */}
      <div className="mx-auto max-w-2xl lg:mx-0 lg:w-1/2 lg:flex-auto">
        <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {props.headerText}
        </h1>
        <h2 className="mt-2 max-w-lg text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {props.subheaderText}
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          {props.paragraphText}
        </p>
        {props.buttonText && props.buttonLink && (
          <div className="mt-10 flex items-center gap-x-6">
            <OutlineButton text={props.buttonText} link={props.buttonLink} />
          </div>
        )}
      </div>

      {/* Image Section */}
      <div className="mt-16 sm:mt-24 lg:mt-0 lg:w-1/2 lg:flex-shrink-0">
        <div className="border-solid border-2 border-orange-300 rounded-md">
          <img
            src={props.imageSource}
            alt={props.imageAltText}
            className="w-full h-auto rounded-md object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroHeader;
