import React from "react";
import HeroHeader from "../elements/HeroHeader";
import FullHeightImageText from "../elements/FullHeightImageText";
import BackgroundGrid from "../components/BackgroundGrid";
import BaptismImage from "../assets/baptism-service.jpg";
import MotherDaughter from "../assets/mother-daughter.jpg";
import { HeartIcon, UsersIcon } from "@heroicons/react/24/outline"; // Assuming WaterIcon is available or use a similar icon

const Baptisms = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        {/* Hero Section for Baptisms */}
        <HeroHeader
          headerText={"Baptisms at St. Bartholomew's Church"}
          subheaderText={"Welcome to the Family of Faith"}
          paragraphText={
            "Baptism marks the beginning of a lifelong journey of faith. At St. Bartholomew's, we are honoured to walk alongside you and your family as you celebrate this sacred occasion and embrace God’s love through the sacrament of baptism."
          }
          imageSource={BaptismImage}
          imageAltText={"Baptism at St. Bartholomew's Church"}
        />

        {/* Full-height section for additional service details */}
        <FullHeightImageText
          imageSrc={MotherDaughter}
          imageAlt="Baptism at St. Bartholomew's Church"
          preTitle="Celebrate New Life"
          title="Baptisms at St. Bartholomew's Church"
          introParagraphs={[
            "Baptism is a time to celebrate God's grace and welcome new members into the Christian community. Whether it's for a baby, child, or adult, our clergy are here to help you prepare for this special day.",
            "From the preparation leading up to the day of the ceremony, we ensure that your baptism is a time of joy, renewal, and spiritual growth for you and your family.",
          ]}
          listItems={[
            {
              icon: <UsersIcon className="h-5 w-5" />, // Replace with any suitable icon for baptism or water
              title: "Meaningful Ceremonies",
              description:
                "Each baptism ceremony is thoughtfully planned to reflect the significance of this sacred sacrament and your faith journey.",
            },
            {
              icon: <UsersIcon className="h-5 w-5" />,
              title: "Supportive Community",
              description:
                "Our church community stands with you, offering support and encouragement as you begin or continue your spiritual journey.",
            },
            {
              icon: <HeartIcon className="h-5 w-5" />,
              title: "A Welcoming Environment",
              description:
                "Experience the warmth and joy of our church as we welcome you and your family into the Christian community.",
            },
          ]}
          additionalParagraphs={[
            "We are here to guide you through the process of baptism, answer any questions you may have, and make sure your ceremony is as special as the occasion itself.",
            "To learn more about preparing for baptism or to schedule a consultation, please contact our clergy.",
          ]}
          finalHeading="Join the Family of Faith"
          finalParagraph="If you are ready to take the next step in your faith journey through baptism, or if you’d like to know more, we are here to help you."
          buttonText="Contact Us"
          buttonLink="/baptisms/contact"
        />
      </div>
    </div>
  );
};

export default Baptisms;
