// FullHeightImageText.tsx

import React from "react";
import { OutlineButton } from "../components/button";

interface ListItem {
  icon: React.ReactNode;
  title: string;
  description: string;
}

interface FullHeightImageTextProps {
  imageSrc: string;
  imageAlt: string;
  preTitle: string;
  title: string;
  introParagraphs: string[];
  listItems: ListItem[];
  additionalParagraphs?: string[];
  finalHeading?: string;
  finalParagraph?: string;
  buttonText?: string;
  buttonLink?: string;
}

const FullHeightImageText: React.FC<FullHeightImageTextProps> = ({
  imageSrc,
  imageAlt,
  preTitle,
  title,
  introParagraphs,
  listItems,
  additionalParagraphs = [],
  finalHeading,
  finalParagraph,
  buttonText,
  buttonLink,
}) => {
  return (
    <div className="relative bg-transparent">
      <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
        {/* Image Section */}
        <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
            <img
              alt={imageAlt}
              src={imageSrc}
              className="absolute inset-0 h-full w-full bg-gray-50 object-cover"
            />
          </div>
        </div>
        {/* Text Content */}
        <div className="px-6 lg:contents">
          <div className="mx-auto max-w-2xl pb-24 pt-16 sm:pb-32 sm:pt-20 lg:ml-8 lg:mr-0 lg:w-full lg:max-w-lg lg:flex-none lg:pt-32 xl:w-1/2">
            {/* Pre Title */}
            {preTitle && (
              <p className="text-base font-semibold leading-7 text-indigo-600">
                {preTitle}
              </p>
            )}
            {/* Main Title */}
            {title && (
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h1>
            )}
            {/* Intro Paragraphs */}
            {introParagraphs &&
              introParagraphs.length > 0 &&
              introParagraphs.map((para, index) => (
                <p key={index} className="mt-6 text-xl leading-8 text-gray-700">
                  {para}
                </p>
              ))}
            {/* List Section */}
            {listItems && listItems.length > 0 && (
              <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none">
                <ul className="mt-8 space-y-8 text-gray-600">
                  {listItems.map((item, index) => (
                    <li key={index} className="flex gap-x-3">
                      <span className="mt-1 h-5 w-5 flex-none text-indigo-600">
                        {item.icon}
                      </span>
                      <span>
                        <strong className="font-semibold text-gray-900">
                          {item.title}
                        </strong>{" "}
                        {item.description}
                      </span>
                    </li>
                  ))}
                </ul>
                {/* Additional Paragraphs */}
                {additionalParagraphs &&
                  additionalParagraphs.length > 0 &&
                  additionalParagraphs.map((para, index) => (
                    <p key={index} className="mt-8">
                      {para}
                    </p>
                  ))}
                {/* Final Heading and Paragraph */}
                {finalHeading && (
                  <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                    {finalHeading}
                  </h2>
                )}
                {finalParagraph && <p className="mt-6">{finalParagraph}</p>}
                {/* Optional Button */}
                {buttonText && buttonLink && (
                  <div className="mt-8">
                    <OutlineButton text={buttonText} link={buttonLink} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullHeightImageText;
