import React from "react";
import ChurchService from "../assets/church-service.jpg";

const serviceTimes = [
  { id: 1, day: "Sunday", time: "11:00 AM" },
  { id: 2, day: "Monday", time: "No Service" },
  { id: 3, day: "Tuesday", time: "7:00 PM" },
  { id: 4, day: "Thursday", time: "12:30 PM" },
  { id: 5, day: "Saturday", time: "5:00 PM" },
];

const ServiceTimes = () => {
  return (
    <div className="relative bg-transparent">
      <img
        alt="Church service"
        src={ChurchService}
        className="hidden lg:block lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-1/2 object-cover"
      />
      <div className="bg-amber-100">
        <div className="mx-auto grid max-w-7xl lg:grid-cols-2">
          <div className="px-6 pb-12 pt-16 sm:pb-20 sm:pt-20 lg:col-start-2 lg:px-8 lg:pt-32 lg:pb-24">
            <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
              <h2 className="text-base font-semibold leading-8 text-indigo-600">
                Service Times
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Join Us for Worship
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We welcome you to join us at St. Bartholomew's for our regular
                worship services. Our doors are open to everyone.
              </p>
              <dl className="mt-8 sm:mt-16 grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-4">
                {serviceTimes.map((service) => (
                  <div
                    key={service.id}
                    className="flex flex-col gap-y-1 border-l border-gray-900/10 pl-4"
                  >
                    <dt className="text-sm leading-6 text-gray-600">
                      {service.day}
                    </dt>
                    <dd className="order-first text-xl font-semibold tracking-tight text-gray-900">
                      {service.time}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceTimes;
