import React from "react";
import HeroHeader from "../elements/HeroHeader";
import TextAndButtonLeftImageRight from "../elements/TextAndButtonLeftImageRight";
import MultiplePost from "../elements/MultiplePost";
import ServiceTimes from "../elements/ServiceTimes";
import BackgroundGrid from "../components/BackgroundGrid";
import Church1 from "../assets/church-1.jpg";
import Church2 from "../assets/church-2.jpg";
import Church3 from "../assets/church-3.jpg";
import Church4 from "../assets/church-4.jpg";

const Home = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        <HeroHeader
          headerText={"St Bartholomew's Church Hello World"}
          subheaderText={"Newbiggin-by-the-Sea"}
          paragraphText={
            "Welcome to St. Bartholomew's Church, a beacon of faith and community in the heart of Newbiggin-by-the-Sea. Our doors are open to all who seek solace, fellowship, and a deeper connection with God. Join us in worship, reflection, and the celebration of our shared journey in faith."
          }
          buttonText={"Learn more"}
          buttonLink={"/#"}
          imageSource={
            "https://upload.wikimedia.org/wikipedia/commons/f/f5/St_Bartholomews_church_-_geograph.org.uk_-_54862.jpg"
          }
          imageAltText={"St Bartholomew's Church"}
        />

        <ServiceTimes />

        <TextAndButtonLeftImageRight
          headerText={"Develop a daily connection with God"}
          paragraphText={
            "Connect with God with the new Everyday Faith app. Journey daily with reflections to inspire, equip and encourage you in your everyday faith. Everyday Faith is now available to download for iOS and Android. If you already have the Watch & Pray app installed, you will automatically get the update."
          }
          buttonText={"Find out more"}
          buttonLink={"#"}
          imageSource={Church1}
          imageAltText={"A view inside the church"}
        />

        <TextAndButtonLeftImageRight
          headerText={"Develop a daily connection with God"}
          paragraphText={
            "Connect with God with the new Everyday Faith app. Journey daily with reflections to inspire, equip and encourage you in your everyday faith. Everyday Faith is now available to download for iOS and Android. If you already have the Watch & Pray app installed, you will automatically get the update."
          }
          buttonText={"Find out more"}
          buttonLink={"#"}
          imageSource={Church1}
          imageAltText={"A view inside the church"}
          reverseOrder={true}
        />

        <MultiplePost
          posts={[
            {
              id: 1,
              title: "Worship with us",
              href: "#",
              description:
                "Join us for Sunday worship at 10:30am. Our services are open to all and are a time of reflection, prayer, and community.",
              imageUrl: Church2,
            },
            {
              id: 2,
              title: "Worship with us",
              href: "#",
              description:
                "Join us for Sunday worship at 10:30am. Our services are open to all and are a time of reflection, prayer, and community.",
              imageUrl: Church3,
            },
            {
              id: 3,
              title: "Worship with us",
              href: "#",
              description:
                "Join us for Sunday worship at 10:30am. Our services are open to all and are a time of reflection, prayer, and community.",
              imageUrl: Church4,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Home;
