import React from "react";
import BackgroundGrid from "../components/BackgroundGrid";
import EasterService from "../assets/easter-eggs.jpg";
import EasterMorning from "../assets/easter-flower.jpg";
import HeroHeader from "../elements/HeroHeader";

const Easter = () => {
  return (
    <div className="bg-gray-100">
      {/* Festive Spring Background */}
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        {/* Hero Section for Easter */}
        <HeroHeader
          headerText={"Celebrate Easter at St. Bartholomew's Church"}
          subheaderText={"He is Risen!"}
          paragraphText={
            "Join us this Easter as we celebrate the resurrection of Christ and the hope of new life. Experience the joy of Easter in our special services and events."
          }
          imageSource={EasterService}
          imageAltText={"Easter service at St. Bartholomew's Church"}
        />

        {/* New: Easter Events Grid */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-4xl font-bold text-center text-yellow-700">
              Easter Events
            </h2>
            <p className="text-center mt-4 text-gray-700">
              Discover the special services and events happening this Easter
              season at St. Bartholomew's.
            </p>

            {/* Grid Layout for Events */}
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img
                  src={EasterMorning}
                  alt="Easter Sunrise Service"
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-yellow-700">
                    Easter Sunrise Service
                  </h3>
                  <p className="mt-2 text-gray-600">
                    Begin Easter morning with a beautiful sunrise service,
                    celebrating the resurrection of Jesus in the peaceful
                    morning light.
                  </p>
                  <a
                    href="/#"
                    className="inline-block mt-4 text-yellow-700 font-semibold hover:text-yellow-800"
                  >
                    Learn more →
                  </a>
                </div>
              </div>

              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img
                  src={EasterService}
                  alt="Easter Sunday Service"
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-yellow-700">
                    Easter Sunday Service
                  </h3>
                  <p className="mt-2 text-gray-600">
                    Join us for our main Easter Sunday service, where we gather
                    in celebration of the risen Christ.
                  </p>
                  <a
                    href="/#"
                    className="inline-block mt-4 text-yellow-700 font-semibold hover:text-yellow-800"
                  >
                    Learn more →
                  </a>
                </div>
              </div>

              {/* Add more Easter events here if needed */}
            </div>
          </div>
        </section>

        {/* New: Reflection or Message Section */}
        <section className="bg-yellow-50 py-16">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold text-yellow-700">
              An Easter Message from Our Clergy
            </h2>
            <blockquote className="mt-8 text-xl italic text-gray-700">
              "Easter reminds us that no matter what challenges we face, there
              is always hope, and new life awaits. Join us as we celebrate the
              resurrection of Christ and the renewal of our hearts."
            </blockquote>
            <p className="mt-4 text-gray-600">- Reverend John Doe</p>
          </div>
        </section>

        {/* Call-to-Action for Easter Giving */}
        <section className="bg-gray-900 py-16 text-white">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold">Easter Giving and Donations</h2>
            <p className="mt-4 max-w-xl mx-auto text-gray-300">
              This Easter, consider supporting the mission of St. Bartholomew's
              Church. Your contributions help us continue our outreach and
              ministry work in the community.
            </p>
            <a
              href="/donate"
              className="mt-8 inline-block bg-yellow-700 text-white py-3 px-6 rounded-full font-semibold hover:bg-yellow-600 transition"
            >
              Donate Now
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Easter;
