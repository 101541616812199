import React from "react";
import HeroHeader from "../elements/HeroHeader";
import FullHeightImageText from "../elements/FullHeightImageText";
import BackgroundGrid from "../components/BackgroundGrid";
import FuneralImage from "../assets/funeral-service.jpg";
import {
  HeartIcon,
  GlobeAltIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const Funerals = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        {/* Hero Section for Funerals */}
        <HeroHeader
          headerText={"Funerals at St. Bartholomew's Church"}
          subheaderText={"A Place of Comfort and Reflection"}
          paragraphText={
            "At St. Bartholomew's Church, we offer compassionate and respectful funeral services that honour the life and memory of your loved ones. Our historic church provides a serene and comforting setting for families to come together in remembrance and reflection."
          }
          imageSource={FuneralImage}
          imageAltText={"Funeral service at St. Bartholomew's Church"}
        />

        {/* Full-height section for additional service details */}
        <FullHeightImageText
          imageSrc={FuneralImage}
          imageAlt="Funeral service at St. Bartholomew's Church"
          preTitle="Honour and Remember"
          title="Funeral Services at St. Bartholomew's"
          introParagraphs={[
            "Our clergy and community are here to help you plan a meaningful and respectful service that honours your loved one's life. Whether you're planning a service in advance or in a time of need, we provide compassionate guidance every step of the way.",
            "We understand that each life is unique, and our clergy will work with you to create a personalised service that reflects the values, beliefs, and wishes of the deceased and their family.",
          ]}
          listItems={[
            {
              icon: <HeartIcon className="h-5 w-5" />,
              title: "Personalised Ceremonies",
              description:
                "Tailor the service to reflect the life and personality of your loved one, with readings, music, and prayers that hold meaning for your family.",
            },
            {
              icon: <UsersIcon className="h-5 w-5" />,
              title: "Compassionate Support",
              description:
                "Our clergy and community provide emotional and spiritual support throughout the planning process and the service.",
            },
            {
              icon: <GlobeAltIcon className="h-5 w-5" />,
              title: "Beautiful Setting",
              description:
                "St. Bartholomew's offers a peaceful and historic setting where family and friends can come together in remembrance and reflection.",
            },
          ]}
          additionalParagraphs={[
            "In times of loss, we strive to provide a supportive and caring environment where families can find comfort and hope.",
            "For more information or to discuss funeral arrangements, please contact our clergy, who will be glad to assist you with any questions or special requests.",
          ]}
          finalHeading="Reach Out for Support"
          finalParagraph="If you would like to discuss funeral arrangements or learn more about the services we offer, please don't hesitate to reach out to us."
          buttonText="Contact Us"
          buttonLink="/funerals/contact"
        />
      </div>
    </div>
  );
};

export default Funerals;
