import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Weddings from "./pages/Weddings";
import Funerals from "./pages/Funerals";
import Baptisms from "./pages/Baptisms";
import Confirmations from "./pages/Confirmations";
import Christmas from "./pages/Christmas";
import Easter from "./pages/Easter";
import Contact from "./pages/Contact";
import Layout from "./pages/Layout";
import "./App.css";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/weddings" element={<Weddings />} />
          <Route path="/funerals" element={<Funerals />} />
          <Route path="/baptisms" element={<Baptisms />} />
          <Route path="/confirmations" element={<Confirmations />} />
          <Route path="/christmas" element={<Christmas />} />
          <Route path="/easter" element={<Easter />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
