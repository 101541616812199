import React from "react";
import BackgroundGrid from "../components/BackgroundGrid";
import ChristmasTree from "../assets/christmas1.jpg";
import CandlelightService from "../assets/christmas2.jpg";
import HeroHeader from "../elements/HeroHeader";

const Christmas = () => {
  return (
    <div className="bg-gray-100">
      {/* Festive Snowfall Background */}
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        <HeroHeader
          headerText={"Celebrate Christmas at St. Bartholomew's Church"}
          subheaderText={"Merry Christmas"}
          paragraphText={
            "Join us for a season of joy, peace, and reflection as we celebrate the birth of Christ."
          }
          imageSource={ChristmasTree}
          imageAltText={"Baptism at St. Bartholomew's Church"}
        />

        {/* New: Christmas Events Grid */}
        <section className="py-20">
          <div className="max-w-7xl mx-auto px-6">
            <h2 className="text-4xl font-bold text-center text-red-700">
              Christmas Events
            </h2>
            <p className="text-center mt-4 text-gray-700">
              Discover all the special services and events happening at St.
              Bartholomew's this Christmas season.
            </p>

            {/* Grid Layout for Events */}
            <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img
                  src={ChristmasTree}
                  alt="Christmas Tree Lighting"
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-red-700">
                    Christmas Tree Lighting
                  </h3>
                  <p className="mt-2 text-gray-600">
                    Join us for our annual Christmas tree lighting ceremony,
                    bringing the community together in festive spirit.
                  </p>
                  <a
                    href="/#"
                    className="inline-block mt-4 text-red-700 font-semibold hover:text-red-800"
                  >
                    Learn more →
                  </a>
                </div>
              </div>

              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img
                  src={CandlelightService}
                  alt="Candlelight Service"
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-red-700">
                    Christmas Eve Candlelight Service
                  </h3>
                  <p className="mt-2 text-gray-600">
                    Experience the beauty and peace of our traditional
                    candlelight service on Christmas Eve.
                  </p>
                  <a
                    href="/#"
                    className="inline-block mt-4 text-red-700 font-semibold hover:text-red-800"
                  >
                    Learn more →
                  </a>
                </div>
              </div>

              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img
                  src={CandlelightService}
                  alt="Candlelight Service"
                  className="w-full h-56 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-red-700">
                    Christmas Eve Candlelight Service
                  </h3>
                  <p className="mt-2 text-gray-600">
                    Experience the beauty and peace of our traditional
                    candlelight service on Christmas Eve.
                  </p>
                  <a
                    href="/#"
                    className="inline-block mt-4 text-red-700 font-semibold hover:text-red-800"
                  >
                    Learn more →
                  </a>
                </div>
              </div>

              {/* Add more events here as needed */}
            </div>
          </div>
        </section>

        {/* New: Testimonial or Reflection Section */}
        <section className="bg-red-50 py-16">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold text-red-700">
              A Christmas Message from Our Clergy
            </h2>
            <blockquote className="mt-8 text-xl italic text-gray-700">
              "Christmas is a time to reflect on the incredible gift of Christ's
              birth. It is a season of love, hope, and renewal. We invite you to
              join us this Christmas to celebrate and share in this message of
              peace."
            </blockquote>
            <p className="mt-4 text-gray-600">- Reverend John Doe</p>
          </div>
        </section>

        {/* Footer or Christmas Giving Section */}
        <section className="bg-gray-900 py-16 text-white">
          <div className="max-w-7xl mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold">
              Christmas Giving and Donations
            </h2>
            <p className="mt-4 max-w-xl mx-auto text-gray-300">
              This Christmas, consider supporting our church’s mission of
              outreach and charity. Your generosity helps us continue our work
              in the community and beyond.
            </p>
            <a
              href="/donate"
              className="mt-8 inline-block bg-red-700 text-white py-3 px-6 rounded-full font-semibold hover:bg-red-600 transition"
            >
              Donate Now
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Christmas;
