import React from "react";
import { OutlineButton } from "../components/button";

interface TextAndButtonLeftImageRightProps {
  headerText: string;
  paragraphText: string;
  buttonText: string;
  buttonLink: string;
  imageSource: string;
  imageAltText: string;
  reverseOrder?: boolean;
}

const TextAndButtonLeftImageRight = ({
  headerText,
  paragraphText,
  buttonText,
  buttonLink,
  imageSource,
  imageAltText,
  reverseOrder = false,
}: TextAndButtonLeftImageRightProps) => {
  return (
    <div className="mx-auto max-w-7xl px-6 py-6 sm:py-14 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-18">
      <div
        className={`w-full lg:w-1/2 lg:text-center lg:flex lg:flex-col lg:justify-center ${
          reverseOrder ? "lg:order-2" : ""
        }`}
      >
        <h2 className="text-4xl font-semibold text-black mb-6">{headerText}</h2>

        <p className="text-gray-700 mt-4">{paragraphText}</p>
        <div className="my-6">
          <OutlineButton text={buttonText} link={buttonLink} />
        </div>
      </div>

      <div
        className={`w-full lg:w-1/2 lg:flex-shrink-0 lg:flex lg:items-center lg:justify-center ${
          reverseOrder ? "lg:order-1" : ""
        }`}
      >
        <img
          src={imageSource}
          alt={imageAltText}
          className="max-w-full max-h-screen object-contain border border-2 border-purple-300 rounded-md"
        />
      </div>
    </div>
  );
};

export default TextAndButtonLeftImageRight;
