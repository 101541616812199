import React from "react";
import HeroHeader from "../elements/HeroHeader";
import BackgroundGrid from "../components/BackgroundGrid";
import MultiplePost from "../elements/MultiplePost";
import Cross from "../assets/cross.jpg";

const About = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        <HeroHeader
          headerText={"Meet Our Team"}
          subheaderText={"Dedicated Leaders of St. Bartholomew's"}
          paragraphText={
            "At St. Bartholomew's Church, our dedicated team works tirelessly to foster a welcoming and nurturing environment for our congregation and community. Get to know the leaders and volunteers who inspire our mission and guide our spiritual journey."
          }
          imageSource={Cross}
          imageAltText={"St Bartholomew's Church"}
        />

        <MultiplePost
          posts={[
            {
              id: 1,
              title: "Fr Anthony",
              description:
                "Fr Anthony is the vicar of St. Bartholomew's Church. He has been with the church for over 10 years and is passionate about community outreach and spiritual growth.",
              imageUrl: "https://picsum.photos/500",
            },
            {
              id: 2,
              title: "Hazel Bob",
              description:
                "Hazel Bob is the church warden and has been a member of St. Bartholomew's for over 20 years. She is dedicated to maintaining the church's historic beauty and welcoming atmosphere.",
              imageUrl: "https://picsum.photos/500",
            },
            {
              id: 3,
              title: "Mary Smith",
              description:
                "Mary Smith is the head of the church choir and has been a member of St. Bartholomew's for over 15 years. She is passionate about music and worship.",
              imageUrl: "https://picsum.photos/500",
            },
            {
              id: 4,
              title: "Mary Smith",
              description:
                "Mary Smith is the head of the church choir and has been a member of St. Bartholomew's for over 15 years. She is passionate about music and worship.",
              imageUrl: "https://picsum.photos/500",
            },
            {
              id: 5,
              title: "Mary Smith",
              description:
                "Mary Smith is the head of the church choir and has been a member of St. Bartholomew's for over 15 years. She is passionate about music and worship.",
              imageUrl: "https://picsum.photos/500",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default About;
