import React from "react";
import HeroHeader from "../elements/HeroHeader";
import FullHeightImageText from "../elements/FullHeightImageText";
import BackgroundGrid from "../components/BackgroundGrid";
import ConfirmationGlass from "../assets/confirmation-glass.jpg";
import ChurchInside from "../assets/church-service.jpg";
import {
  HeartIcon,
  UsersIcon,
  LightBulbIcon,
} from "@heroicons/react/24/outline";

const Confirmations = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        {/* Hero Section for Confirmations */}
        <HeroHeader
          headerText={"Confirmations at St. Bartholomew's Church"}
          subheaderText={"Strengthen Your Faith and Commitment"}
          paragraphText={
            "Confirmation is an important step in the Christian journey, marking a time when individuals affirm their faith and commitment to following Christ. At St. Bartholomew's, we provide guidance and support as you take this meaningful step."
          }
          imageSource={ConfirmationGlass}
          imageAltText={"Confirmation service at St. Bartholomew's Church"}
        />

        {/* Full-height section for additional service details */}
        <FullHeightImageText
          imageSrc={ChurchInside}
          imageAlt="Confirmation service at St. Bartholomew's Church"
          preTitle="Affirm Your Faith"
          title="Confirmations at St. Bartholomew's"
          introParagraphs={[
            "Confirmation is an opportunity to publicly affirm your faith and become more deeply involved in the life of the church. At St. Bartholomew's, we provide a thoughtful preparation process that helps individuals understand the significance of confirmation and what it means to be a committed Christian.",
            "Our clergy and church community will be with you every step of the way, offering guidance, education, and support as you prepare for this important moment in your faith journey.",
          ]}
          listItems={[
            {
              icon: <LightBulbIcon className="h-5 w-5" />,
              title: "Spiritual Formation",
              description:
                "Through classes and mentorship, we help you deepen your understanding of Christian faith and practice.",
            },
            {
              icon: <UsersIcon className="h-5 w-5" />,
              title: "Community Support",
              description:
                "Our church community stands beside you as you make this public affirmation of your faith and commitment to Christ.",
            },
            {
              icon: <HeartIcon className="h-5 w-5" />,
              title: "Personalised Guidance",
              description:
                "We provide one-on-one support to ensure you feel confident and ready to take this important step in your spiritual life.",
            },
          ]}
          additionalParagraphs={[
            "Confirmation is a personal and transformative experience, and we are here to ensure it is both meaningful and memorable.",
            "For more information on how to prepare for confirmation or to speak with our clergy, please reach out to us.",
          ]}
          finalHeading="Begin Your Confirmation Journey"
          finalParagraph="If you’re ready to explore confirmation and deepen your commitment to Christ, we invite you to contact us for more information or to begin the preparation process."
          buttonText="Contact Us"
          buttonLink="/confirmations/contact"
        />
      </div>
    </div>
  );
};

export default Confirmations;
