import React from "react";
// import HeroHeaderAlt from "../elements/HeroHeaderAlt";
import BackgroundGrid from "../components/BackgroundGrid";
import WeddingHeader from "../assets/wedding-header.jpg";
import FullHeightImageText from "../elements/FullHeightImageText";
import WeddingFullHeight from "../assets/wedding-full-height.jpg";
import {
  GlobeAltIcon,
  HeartIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import HeroHeader from "../elements/HeroHeader";

const Weddings = () => {
  return (
    <div className="bg-white">
      <div className="relative isolate pt-14">
        <BackgroundGrid />

        {/* <HeroHeaderAlt
          headerText={"Weddings at St. Bartholomew's"}
          subheaderText={"Celebrate Your Love in Sacred Spaces"}
          paragraphText={
            "At St. Bartholomew's Church, we provide beautiful and intimate wedding ceremonies that honor your love and commitment. Nestled in the heart of Newbiggin-by-the-Sea, our serene and historic setting serves as the perfect backdrop for your special day. Our dedicated community and experienced clergy are here to guide you through every step, ensuring a memorable and meaningful celebration of your union."
          }
          imageSource={WeddingHeader}
          imageAltText={"Wedding ceremony at St. Bartholomew's Church"}
        /> */}

        <HeroHeader
          headerText={"Weddings at St. Bartholomew's Church"}
          subheaderText={"Celebrate Your Love in Sacred Spaces"}
          paragraphText={
            "At St. Bartholomew's Church, we provide beautiful and intimate wedding ceremonies that honor your love and commitment. Nestled in the heart of Newbiggin-by-the-Sea, our serene and historic setting serves as the perfect backdrop for your special day. Our dedicated community and experienced clergy are here to guide you through every step, ensuring a memorable and meaningful celebration of your union."
          }
          imageSource={WeddingHeader}
          imageAltText={"Wedding ceremony at St. Bartholomew's Church"}
        />

        <FullHeightImageText
          imageSrc={WeddingFullHeight}
          imageAlt="Wedding ceremony at St. Bartholomew's Church"
          preTitle="Celebrate Your Love"
          title="Weddings at St. Bartholomew's Church"
          introParagraphs={[
            "Celebrate your love in the tranquil and historic setting of St. Bartholomew's Church. Our beautiful sanctuary provides a serene backdrop for your wedding ceremony, surrounded by the love and support of your family and friends.",
            "Our experienced clergy will work with you to create a personalized and meaningful service that reflects your unique relationship and shared values. From heartfelt vows to traditional rituals, we ensure every detail is thoughtfully planned.",
          ]}
          listItems={[
            {
              icon: <HeartIcon className="h-5 w-5" />,
              title: "Personalized Ceremonies",
              description:
                "Customize your wedding ceremony to reflect your unique love story and spiritual journey.",
            },
            {
              icon: <UsersIcon className="h-5 w-5" />,
              title: "Experienced Clergy",
              description:
                "Our dedicated clergy guide you through every step, ensuring a memorable and meaningful celebration.",
            },
            {
              icon: <GlobeAltIcon className="h-5 w-5" />,
              title: "Beautiful Decor",
              description:
                "Enjoy elegant decorations and a picturesque setting that enhances the beauty of your union.",
            },
          ]}
          additionalParagraphs={[
            "Our dedicated community and experienced clergy are here to guide you through every step, ensuring a memorable and meaningful celebration of your union.",
            "From personalized vows to thoughtful planning assistance, we are here to support you every step of the way.",
          ]}
          finalHeading="Start Your Journey Together"
          finalParagraph="Begin your life together in the sacred and welcoming environment of St. Bartholomew's Church. Let us help you craft a personalized ceremony that reflects your unique love story and spiritual journey."
          buttonText="Schedule a Consultation"
          buttonLink="/weddings/contact"
        />
      </div>
    </div>
  );
};

export default Weddings;
